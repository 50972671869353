<template>
    <header>
        <div class="header_center">
            <router-link to="/" class="mobile_hide header_logo_desktop"><b>CDI</b> CUSTOM CURB ADAPTERS</router-link>
            <ul class="list-unstyled list-inline header_list header_phone">
                <li>888-234-7001</li>
            </ul>
            <ul v-click-outside="handleOutsideClick" class="list-unstyled list-inline header_list">
                <li class="header_sign_in">
                    <div v-if="!appState.loggedIn" class="dropdown">
                        <div>
                            <router-link :to="'/my'" class="btn btn-default create-account" tabindex="4">
                                        <span>
                                            SIGN IN
                                        </span>
                            </router-link>
                        </div>
                    </div>
                    <div v-else-if="!appState.loggedIn" class="dropdown" >
                        <div>
                            <button class="btn btn-default btn_user" @click="toggleLogIn">SIGN IN</button>
                            <div class="dropdown-menu dropdown-menu-right login_panel" :class="[showLogIn ? 'cdi_show' : 'cdi_hide']">
                                <form action="/my/signin" method="post" @submit.prevent="signIn">
                                    <div class="form-group">
                                        <label>Email</label><input type="text" class="form-control" tabindex="1" v-model="formFields.email.val">
                                    </div>
                                    <div class="form-group">
                                        <label>Password</label><a href="/pass_reset" class="pull-right small forgot-pass" tabindex="5">Forgot password?</a><input type="password" class="form-control" tabindex="2" v-model="formFields.password.val">
                                    </div>
                                    <input type="submit" value="Sign In" class="btn btn-primary" tabindex="3">
                                    <router-link :to="'/my/register'" class="btn btn-default create-account" tabindex="4">
                                        <span @click="handleOutsideClick">
                                            Create Your Account
                                        </span>
                                    </router-link>
                                </form>
                            </div>
                        </div>
                    </div>
                    <HeaderMyCDI v-else />
                </li>
              <li class="header_create_quote">
              <router-link v-show="appState.user.userTypeID != 5" class="btn btn-primary" :to="`/quote`" @click.native="setView('override_new')">CREATE A QUOTE</router-link>
              </li>
            </ul>
        </div>
    </header>
</template>

<script>
import clickOutside from "@/directives/clickOutside";
import {store} from "@/store/BusinessObject.store";
import HeaderMyCDI from "@/components/layout/HeaderMyCDI";


export default {
    name: "Header",
    components: { HeaderMyCDI },
    data() {
        return {
            appState: this.$appStore.state,
            showLogIn: false,
            response: {},
            formFields: {
                email: { val: '', qsField: '[email]' },
                password: { val: '', qsField: '[passwd]' },
            },
          state: store.state
        }
    },
    methods: {
        toggleLogIn: function() {
            this.showLogIn = !this.showLogIn;
        },
        signIn: function() {
            this.$appStore.signIn(this.formFields);
            this.showLogIn = false;
            this.formFields.email.val = '';
            this.formFields.password.val = '';
        },
        handleOutsideClick: function () {
            this.showLogIn = false;
            this.showMyCDI = false;
        },
        setView: function(view) {
            this.state.currentView = view;
        },
    },
    directives: {
        clickOutside: clickOutside
    }
}
</script>

<style scoped>
.dropdown-menu {
    min-width: 210px !important;
}

@media (max-width: 850px) {
    .create-account {
        width: 100%;
        min-width: 100px!important;
        font-size: 11px!important;
    }
    .forgot-pass{
        float: left!important;
    }
}
</style>

<template>
    <div id="app" class="container container_site">
        <div>
            <div class="navbar-fixed-top wrapper">
                <div class="header"><Header/></div>
                <div class="nav"><Nav/></div>
            </div>
            <br/>
            <div class="row">
               <router-view/>
            </div>

            <Footer/>

            <MessageBox v-show="this.$appStore.state.appMessage"/>
            <ErrorBox v-show="this.$appStore.state.errorText"/>
            <WarningBox v-show="this.$appStore.state.warningText"/>
            <MassFileUpload v-show="this.$appStore.state.fileUploadModalOpen"/>
            <FileUpload ref="fileUploader" v-show="this.$appStore.state.fileUploadContext"/>
            <QrLink v-show="this.$appStore.hasRole([cdiVars.CDI_ROLE_SHOP])" />

            <div v-show="!isProduction()" class="alert alert-danger dev-notice">
                <p>This is a <strong>development</strong> server</p>
            </div>
            <FeedBack></FeedBack>
        </div>
    </div>
</template>

<script>
import Header from './components/layout/Header.vue';
import Footer from './components/layout/Footer.vue';
import Nav from './components/layout/Nav.vue';
import MessageBox from "./components/utils/MessageBox";
import ErrorBox from "./components/utils/ErrorBox";
import WarningBox from "@/components/utils/WarningBox";
import FileUpload from "@/components/utils/fileHandlers/FileUpload";
import MassFileUpload from "@/components/utils/fileHandlers/MassFileUpload";
import FeedBack from "@/components/layout/FeedBack";
import QrLink from "@/components/utils/QrLink.vue";
import {cdiVars} from "@/cdiVars";


export default {
    name: 'app',
    components: {
        QrLink,
        MassFileUpload,
        FileUpload,
        WarningBox,
        ErrorBox,
        Header,
        Nav,
        Footer,
        MessageBox,
        FeedBack
    },
    data() {
        return {
            appState: this.$appStore.state
        }
    },

    computed: {
        cdiVars() {
            return cdiVars
        },
        loggedIn: function () {
            return this.appState.loggedIn;
        },
    },

    methods: {
        isProduction: function () {
            return process.env.VUE_APP_URL === 'https://www.cdicurbs.com';
        }
    },
    watch: {
        $route: {
            handler(to) {
                to.meta.fullWidth === true ? document.body.classList.add('full_width') : document.body.classList.remove('full_width');
            },
        },

        loggedIn(to, from) {
            if ((to === true && from === false && this.$route.meta.redirectToMy === true) || to === false && from === true) {
                this.$router.push({path: '/my'});
            } else {
                this.$router.go();
            }

        },

    },
}
</script>

<style>
@import 'assets/css/bootstrap.min.css';
@import 'assets/css/cdi.css';

#app {

}

.dev-notice {
    position: fixed;
    margin: 0;
    bottom: 10px;
    right: 10px;
    text-align: center !important;
    z-index: 10000;
    opacity: 0.85;
}

@media screen and (max-width: 850px) {
    .wrapper {
        display: flex;
        flex-direction: column;
    }
    .wrapper div:first-child {
        order: 1;
        padding-top: 5px;
    }
    .nav{
        z-index: 1000!important;
    }
    .header{
        top: 50px;
        position: absolute;
        z-index: 2!important;
        width: 100%;
        margin-bottom: 10px;
        padding-right: 0px!important;
    }
    .row{
        margin-top: 10px;
        margin-right: 0px!important;
    }
}

</style>

<template>
    <div class="sticky-button">
        <router-link to="/qrscanner"><button href="#scan" class="btn btn-info">
            <span class="glyphicon glyphicon-qrcode"></span>
        </button></router-link>
    </div>
</template>

<script>
    export default {
        name: 'QrLink'
    }
</script>

<style scoped>
.sticky-button {
    position: fixed;
    bottom: 100px;
    right: 20px;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 30px;
}

.btn-text {
    transform: rotate(90deg);
    margin-left: 10px;
}
</style>
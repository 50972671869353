<template>
    <footer class="footer text-center text-uppercase">
        <ul class="list-unstyled list-inline">
            <li>888-234-7001</li>
            <li class="square mobile_hide"></li>
            <li>Proudly Made in America</li>
            <li class="square mobile_hide"></li>
            <li><a href="/locations">Locations</a></li>
            <li class="square mobile_hide"></li>
            <li><a href="/employment">Employment</a></li>
            <li class="square mobile_hide"></li>
            <li><a href="https://cdicurbs.atlassian.net/wiki/external/MjQzYjI1ZTAzOTJjNDc5Mzk2NGE1ZjZjN2FlZGZjYjI">API</a></li>
        </ul>
    </footer>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>

<template>
    <div>
        <div class="topnav" id="myTopnav">
           <div class="mobile-header">
            <span @click="toggleLogIn" class="pull-right tiny_show_inline header_nav_menu_mobile" id="mobile_menu_button"> <a href="javascript:void(0);" style="font-size:15px;" class="icon"><span class="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span> MENU</a></span>
            <div class="tiny_show_inline"><a id="home-icon" href="/"><img src="../../assets/cdi-logo-wheel-color-mobile.png" class="name"><b class="name" id="name">CDI CURBS</b> </a> &nbsp;</div>
        </div>
            <ul v-if="loggedIn && appState.user.userTypeID == 4"  v-click-outside="handleOutsideClick" class="nav">
                <li class="dropdown first-dropdown">
                <div class="dropbtn">
                <div class="input-group input_group_omni_search ui-front">
                    <label class="input-group-addon"><span class="glyphicon glyphicon-search"></span></label>
                    <Typeahead cdi_type="omni_search"
                               ref="omniSearch"
                               :minLength="3"
                               :inputAttributes="{class: 'input-lg'}"
                               placeholder="Search"
                               @objectSelected="omniSelect"
                               class="dropbtn"
                    />
                </div>
                </div>
            </li>
                <li class="dropdown">
                <a class="dropbtn" @click="toggleMenu('data')">DATA</a>
                <ul class="dropdown-content" :class="[isOpen('data') ? 'cdi_show' : 'cdi_hide']">
                    <div class="nav-item">
                    <li v-for="(menuItem, item) in dbMenuListFiltered" v-bind:key="item" class="item">
                        <router-link :to="menuItem.value" @click.native="toggleMenu(''); toggleMenuMobile()">{{ menuItem.label }}</router-link>
                    </li>
                    </div>
                </ul>
            </li>
                <li class="dropdown">
                <a class="dropbtn" @click="toggleMenu('scheduler')">SCHEDULERS</a>
                <ul class="dropdown-content" :class="[isOpen('scheduler') ? 'cdi_show' : 'cdi_hide']">
                    <div class="nav-item">
                    <li v-for="(menuItem, item) in schMenuListFiltered" v-bind:key="item" class="item">
                        <router-link v-if="menuItem.value" :to="menuItem.value" @click.native="toggleMenu(''); toggleMenuMobile()">{{ menuItem.label }}</router-link>
                        <div v-else class="separator" id="separator"></div>
                    </li>
                    </div>
                </ul>
            </li>
                <li class="dropdown">
                <a class="dropbtn" @click="toggleMenu('tools')">TOOLS</a>
                <ul class="dropdown-content rightFix" :class="[isOpen('tools') ? 'cdi_show' : 'cdi_hide']">
                    <div class="nav-item">
                    <li v-for="(menuItem, item) in toolsMenuListFiltered" v-bind:key="item" class="item">
                        <a v-if="menuItem.href" :href="menuItem.href">{{ menuItem.label }}</a>
                        <router-link v-else :to="menuItem.value" @click.native="toggleMenu(''); toggleMenuMobile()" class="item">{{ menuItem.label }}</router-link>
                    </li>
                    </div>
                </ul>
            </li>
                <li class="dropdown" >
                <a class="dropbtn" @click="toggleMenu('reports')">REPORTS</a>
                <ul class="dropdown-content rightFix" :class="[isOpen('reports') ? 'cdi_show' : 'cdi_hide']">
                    <div class="nav-item">
                    <li v-for="(menuItem, item) in rptMenuListFiltered" v-bind:key="item" class="item">
                        <router-link v-if="menuItem.value" :to="menuItem.value" @click.native="toggleMenu(''); toggleMenuMobile()" class="item">{{ menuItem.label }}</router-link>
                        <div v-else class="dropdown-header"><strong>{{ menuItem.label }}</strong> </div>
                    </li>
                    </div>
                </ul>

            </li>
                <br/>
            </ul>
        <ul v-if="loggedIn && appState.user.userTypeID !== 4 || !loggedIn" class="nav">
            <li class="dropdown"><a class="dropbtn"><router-link class="item" to="/about" @click.native="toggleMenuMobile()">ABOUT</router-link></a></li>
            <li class="dropdown"><a class="dropbtn"><router-link class="item" to="/products" @click.native="toggleMenuMobile()">PRODUCTS</router-link></a></li>
            <li class="dropdown"><a class="dropbtn"><router-link class="item" to="/tools" @click.native="toggleMenuMobile()">TOOLS</router-link></a></li>
            <li class="dropdown"><a class="dropbtn"><router-link class="item" to="/locations" @click.native="toggleMenuMobile()">LOCATIONS</router-link></a></li>
            <li class="dropdown"><a class="dropbtn"><router-link class="item" to="/employment" @click.native="toggleMenuMobile()">EMPLOYMENT</router-link></a></li>
        </ul>
        </div>
    </div>
</template>
<script>

    import Typeahead from "@/components/utils/typeaheads/Typeahead";
    import Pages from "@/router/pages";
    import helpers from "@/store/helpers";
    import clickOutside from "@/directives/clickOutside";

    export default {
        name: "NavLinks",
        components : {
          Typeahead
        },
        data() {
            return {
                appState: this.$appStore.state,
                openMenu: '',
                pages: Pages.PAGES,
                showLogIn: false,
            }
        },
        methods: {
            toggleMenuMobile: function () {
                if (this.showLogIn) {
                    let x = document.getElementById("myTopnav");
                    if (x.className === "topnav") {
                        x.className += " responsive";
                    } else {
                        x.className = "topnav";
                    }
                    this.showLogIn = false;
                }
            },

            toggleLogIn: function() {
                this.showLogIn = !this.showLogIn;
                let x = document.getElementById("myTopnav");
                if (x.className === "topnav") {
                    x.className += " responsive";
                } else {
                    x.className = "topnav";
                }
            },
            toggleMoreFilters: function () {
                this.toggleHide = !this.toggleHide;
            },
            toggleMenu: function (menu) {
                if (menu == this.openMenu){
                    this.openMenu = '';
                }else {
                    this.openMenu = menu;
                }
            },
            isOpen: function(menu) {
                return (menu == this.openMenu);
            },
            filterMenuList: function(menuList) {
                let filteredList = {};
                for (let key in menuList){

                    if (helpers.canAccess(menuList[key], this.$appStore.state)) {
                        filteredList[key] = menuList[key];
                    }
                }

                return filteredList;
            },
            handleOutsideClick: function () {
                this.toggleMenu('');
            },
            omniSelect: function (object) {
                this.$router.push(object.value);
                this.$refs.omniSearch.$data.object = '';
            },
        },
        created() {
            if (this.appState.user.userTypeID === 4) {
                this.appState.showPublicNav = false;
            }
        },
        computed: {
            dbMenuListFiltered: function() {
                return this.filterMenuList(this.pages.data);
            },
            schMenuListFiltered: function() {
                return this.filterMenuList(this.pages.scheduler);
            },
            toolsMenuListFiltered: function() {
                return this.filterMenuList(this.pages.tools);
            },
            rptMenuListFiltered: function() {
                return this.filterMenuList(this.pages.reports);
            },
            loggedIn: function() {
                return this.appState.loggedIn
            }
        },
        directives: {
            clickOutside: clickOutside
        },
        watch: {
            loggedIn: function() {
                if (this.appState.user.userTypeID == 4 && this.loggedIn) {
                    this.showLogIn = false;
                } else {
                    this.showLogIn = true;
                }
            },
        }
    }
</script>

<style scoped>
.separator{
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #d4d8dd;
    min-width: 160px;
}
#separator:hover{
    background-color: #d4d8dd!important;
}

.topnav .icon {
    display: none;
    text-decoration: none;
    font-weight: bolder;
    color: #333333;
}
.dropdown {
    float: left;
}

.nav{
    top: 0px!important;
    display: inline-block;

}
.name{
    visibility: hidden;
}
.topnav{
    padding-top: 20px;
}

.dropbtn{
    width: fit-content;
    font-size: large;
}
a{
    clear: both;
    font-weight: normal;
    white-space: nowrap;

}

.dropdown-content a{
    color: #333333;
    padding-left: 20px;
    padding-right: 20px;
    display: block;
    width: 100%;
}
.dropdown-content a:hover{
    text-decoration: none;
    color: #fff!important;
    background-color: #3080e8;
}

ul{
    list-style: none;
    padding-left: 0px!important;
}
ul.dropdown-content{
    z-index: 1000!important;
    position: fixed;
    height: 1px;

}
@media screen and (min-width: 850px) and (max-width: 1300px){
    .rightFix {
        left: auto;
        right: 20px;
    }
}

.nav-item{
    min-width: 160px;
    list-style: none;
    font-size: 16.8px;
    text-align: left;
    background-color: #fafbfc;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-bottom: 3px solid #d4d8dd;
    margin-left: -35px!important;
    margin-right: -10px!important;
    max-height: 75vh;
    overflow: auto;
}
.item{
    clear: both;
    font-weight: normal;
    white-space: nowrap;
    padding-top: 3px;
    padding-bottom: 3px;
}
.item:hover{
    text-decoration: none;
}


.dropbtn:hover{
    cursor: pointer;
    background: none!important;
}

@media screen and (max-width: 850px) {
    .topnav{
        display: block;
        background-color: #FFFFFF;
        padding-top: 2px;
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .name{
        visibility: visible;
        float: left;
        padding-left:3px;
    }
    #name{
        margin-top: 6px;
    }
    .topnav a:not(:first-child), .dropdown .dropbtn {
        display: none;
    }
    .topnav a.icon {
        float: right;
        display: block;
        margin-top: 0px;
    }
    .nav{
        width: 100%;
        display: block;
    }
    .topnav.responsive .icon {
        top:0px!important;
        padding-left: 20px;
        padding-bottom: 3px;
        margin-bottom: 3px;
    }
    .topnav.responsive a {
        float: none;
        display: block;
        text-align: left;
    }
    .topnav.responsive .dropdown {
        float: none;
    }
    .topnav.responsive .dropdown-content {
        position: relative;
    }
    .topnav.responsive .dropdown .dropbtn {
        display: block;
        text-align: left;
    }
    .topnav.responsive .dropdown .dropbtn:hover {
       cursor: pointer;
    }
    .topnav.responsive{
        background-color: #fafbfc;
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
        box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border-top: none;
    }
    .input_group_omni_search{
        margin-top: 16px;
    }
    .first-dropdown{
        margin-right: 20px;
    }
    .item {
        padding-left: 30px;
    }
}
#home-icon{
    color: #333333;
    position: absolute;
    left: 15px;
}
</style>
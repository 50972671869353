<template>
    <div>
        <div class="jumbotron hero hero_image">
            <div class="jumbotron_inner">
                <p class="home_lg_text" style="margin-bottom: 5px;">FAST</p>
                <p class="">4 DAY lead times or SAME DAY when you need it!</p><br>
                <p><router-link class="btn btn-primary btn-lg" style="border-bottom: 0;" :to="`/quote`" @click.native="setView('override_new')">ORDER NOW</router-link></p>
            </div>
        </div>
        <div class="spacing_top home_lg_text home_lg_bold_text">Fast &ndash; Easy &ndash; Fun<br>It's a great day at CDI!</div>
        <div class="row">
            <div class="col-lg-4 col-xs-12">
                <div class="home_heading spacing_top">Speed</div>
                <ul class="home_text">
                    <li>Choose the lead time for your product build including same day.</li>
                    <li>Order a same day build of any unitary adapter and <a href="/guarantee">it's guaranteed or it's free</a>.</li>
                    <li>We build and ship curbs 99.3 % on time.</li>
                </ul>
            </div>
            <div class="col-lg-4 col-xs-12">
                <div class="home_heading spacing_top">Technology</div>
                <ul class="home_text">
                    <li>It takes 11 seconds to
                        <router-link :to="`/quote`" @click.native="setView('override_new')">
                            build a quote.
                        </router-link>
                    </li>
                    <li>Instant drawings on 19,000 unique products.</li>
                    <li>Email confirmations when your order is received and when it ships.</li>
                </ul>
            </div>

            <div class="col-lg-4 col-xs-12">
                <div class="home_heading spacing_top">Service</div>
                <ul class="home_text">
                    <li>A real person answers your call. Try it! <a href="tel:+18882347001">1-888-234-7001</a></li>
                    <li>Custom quotes &ndash; 30 minutes or less!</li>
                    <li><a href="/locations">Manufacturing facilities</a> in Charlotte, Dallas, Minneapolis, Seattle and Phoenix.</li>
                </ul>
            </div>
        </div>
        <div class="home_lg_text home_lg_bold_text spacing_top">
            <div class="center-block testimonial">
                &ldquo; We really appreciate the website and how easy it makes the quoting process.  Everyone is  great to deal with and our customer feedback is always positive. &rdquo; &mdash;David
            </div>
        </div>
    </div>
</template>

<script>

import {store} from "@/store/BusinessObject.store";
export default {
    name: 'home',
    components: {},
    data() {
        return {
            appState: this.$appStore.state,
            state: store.state
        }
    },
    methods: {
        setView: function(view) {
            this.state.currentView = view;
        },
    },
}
</script>

<style scoped>

.hero_image {
    background-image: url('../assets/hero-setting-curb.jpg');
    background-size: cover;
}


/*.hero_image {
    background-image: url('https://cdicurbs.com/images/v4/cdi_4july_slider062624.png');
    background-size: cover;
}*/

/*.jumbotron_inner {
    display: none;
}*/

@media screen and (max-width: 1220px) {
     .hero_image{
        background-size: contain;
         background-repeat: no-repeat;
        background-position: center;
         width: 100%!important;
     }
     .hero{
         min-height: 300px;
     }
 }





</style>
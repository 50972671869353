<template>
    <div v-if="isStaff">
        <div v-if="isHidden" class="alert alert-warning dev-notice">
            <p>
                <button class="btn btn-default" @click="toggleMenu">Report a Bug</button>
            </p>
        </div>
        <div v-if="!isHidden" class="alert alert-warning dev-notice">
            <p>Please send a detailed message about the bug you are <br />experiencing and the dev team will work to resolve it as soon as possible.</p>
            <textarea v-model="formField" name="" id="" class="feedback_reply form-control" cols="40"
                      rows="10"></textarea>
            <button class="btn btn-default" @click="submitForm">Submit</button>
            <button class="btn btn-default" @click="closeMenu">Close</button>
        </div>
    </div>
</template>

<script>

import http from "axios";
import {cdiVars} from "@/cdiVars";
import helpers from "@/store/helpers";
import appStore from "@/store/App.store";

export default {
    name: 'FeedBack',

    data() {
        return {
            isHidden: true,
            formField: '',
        }
    },

    methods: {
        toggleMenu: function () {
            if (this.isHidden) {
                this.isHidden = false;
            }
        },
        closeMenu: function () {
            if (!this.isHidden) {
                this.isHidden = true;
            }
        },
        submitForm: function () {
            if (!this.formField == '') {
                http.post(`${cdiVars.API_URL}/site_request/save_feedback/0`, helpers.getQSString({'params[detail]': this.formField + "\n\n" + this.$route.fullPath}), {headers: helpers.authHeader()})
                    .then(() => {
                            this.isHidden = true;
                            this.formField = '';
                            appStore.successBox('Feedback sent.');
                        }
                    );
            }
        }
    },
    computed: {
        isStaff: function () {
            return this.$appStore.isStaff();
        }
    }
}
</script>
<template>
    <nav class="header_nav_container">
        <div class="header_center">
            <router-link to="/" class="header_logo_circle mobile_hide"><img src="../../assets/cdi-logo-wheel-color.png"></router-link>
            <NavLinks />
        </div>
    </nav>
</template>

<script>
    import NavLinks from './NavLinks';
    export default {
        name: "Nav",
        components: {
            NavLinks,
        },
        data() {
            return {
                appState: this.$appStore.state,
            }
        }
    }
</script>

<style scoped>

</style>

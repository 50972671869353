import appStore from "@/store/App.store";
import helpers from "@/store/helpers";
import http from "axios";
import {cdiVars} from "@/cdiVars";
const _ = require('lodash');
export const store = {
    state: {
        name: '',
        title: '',
        titlePlural: '',
        nav: [],
        currentView: 'search',
        object: {
            id: 0,
            fields: [],
            data: {},
            history: {},
        },
        search: {
            endPoint: '',
            sortFields: false,
            formFields: {},
            resultFields: {},
            results: {},
        },
        history: {},
        customFileCallback: null,
        breadcrumbs: [],
        hideNav: false,
    },
    
    initialize: function(activeObjectState) {
        let activeObjectStateClone = _.cloneDeep(activeObjectState);
        if (this.state.currentView.includes('override_')) {
            this.state.currentView = this.state.currentView.split('_')[1];
        } else {
            this.state.currentView = 'search';
        }

        this.state.name = activeObjectStateClone.name;
        this.state.title = activeObjectStateClone.title;
        this.state.titlePlural = activeObjectStateClone.titlePlural;
        this.state.titleAlt = activeObjectStateClone.titleAlt;
        this.state.nav = activeObjectStateClone.nav;
        this.state.search.sortFields = activeObjectStateClone.search.sortFields;
        this.state.search.endPoint = activeObjectStateClone.search.endPoint;
        this.state.search.formFields = activeObjectStateClone.search.formFields;
        this.state.search.resultFields = activeObjectStateClone.search.resultFields;
        this.state.search.results = {};
        this.state.object.fields = activeObjectStateClone.object.fields;
        this.state.object.data = {};
    },
    clearObjectData: function() {
        this.state.object.id = 0;
        this.state.object.data = {};
    },
    
    post: async function(url, qsPairs, resultField){
        appStore.loadingMessage();
        let qsString = helpers.getQSString(qsPairs);
        return await http.post(url, qsString, { headers: helpers.authHeader() })
            .then(res => this.handleResponse(resultField, res.data))
            .catch();
    },

    /**
     * if resultField is a string, this.state.string is updated with the data
     * if resultField is undefined, the data is returned
     */
    handleResponse: function(resultField, data) {
        switch (data.opt) {
            case 'denied':
                appStore.autoSignOut();
                break;
            case 'error':
                appStore.errorBox(data.msg);
                this.state.object.data.opt = 'error';
                if (typeof resultField === 'undefined' || resultField == false) {
                    return 'error';
                }
                break;
            case 'warning':
                appStore.warningBox(data.msg);
                break;
            default:
                appStore.successBox(data.msg);
                if (typeof resultField === 'undefined' || resultField == false) {
                    return data;
                } else {
                    helpers.nestedSet(this.state, resultField, data);
                }
                break;
        }
    },

    api: function(endPoint, options, resultField, restFile = this.state.name) {
        return new Promise(resolve =>
            resolve(this.post(`${cdiVars.API_URL}/${restFile}/${endPoint}/${this.state.object.id}`, options, resultField))
        )
    },

    customApi: function(endPoint, options, resultField) {
        return new Promise(resolve =>
            resolve(this.post(`${cdiVars.API_URL}/${endPoint}`, options, resultField))
        );
    },

    apiWithObjectLoad: function(endPoint, options) {
        return new Promise(resolve =>
            resolve(this.api(endPoint, options, 'object.data'))
        );
    },
    apiGeneric: function(url, options, resultField) {
        return new Promise(resolve =>
            resolve(this.post(`${cdiVars.API_URL}/${url}`, options, resultField))
        );
    },
    search: function(endPoint, options, resultField) {
        return new Promise(resolve =>
            resolve(this.post(`${cdiVars.API_REPORT_URL}/${endPoint}/to_json`, helpers.getQSPairs(options), resultField))
        );
    },
    // runs a search but concats the new rows to the previous ones instead of replacing
    searchMore: function(endPoint, options) {
        this.search(endPoint, options)
            .then(results => {
                results.rows = this.state.search.results.rows.concat(results.rows);
                helpers.nestedSet(this.state, 'search.results', results);
            });
    },
    load: function(id, view = 'details') {
        this.state.breadcrumbs = [];
        this.state.object.id = id;
        this.state.currentView = view;
        return new Promise(resolve =>
            resolve(this.apiWithObjectLoad('to_json', {}))
        );
    },
    save: function(field, val) {
        field = `params[${field}]`;
        return new Promise(resolve =>
            resolve(this.apiWithObjectLoad('save', {[field]: val}))
        );
    },
    getHistory: function() {
        return new Promise(resolve => 
            resolve(this.api(`history_json`, {}, 'history'))
        );
    }
}
